import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { sleep } from '../../../../shared/helpers/helpers.ts';
import { useSelector } from '../../../../shared/store/store.ts';
import s from '../../../../shared/styles/component/admin/admin.module.scss';
import { ReactComponent as ConnectorRestIcon } from '../../../../shared/assets/svg/connector-rest.svg';
import { ReactComponent as ConnectorFTPIcon } from '../../../../shared/assets/svg/connector-sftp.svg';
import { ReactComponent as ConnectorM365Icon } from '../../../../shared/assets/svg/connector-ms365.svg';

const AdminConnectors: React.FC = () => {
  const connectors = useSelector((state) => state.admin.connectors) ?? [];
  const sortedConnectors = [...connectors].sort((a, b) => a.type.localeCompare(b.type));
  const connectorRef = useRef();
  const { connectorId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (connectors.length > 0 && connectorId !== 'new') {
      if (connectorRef.current) {
        const activeConnectorItem = connectorRef.current as HTMLDivElement;
        sleep(1).then(() => {
          activeConnectorItem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        });
      }
    }
  }, [connectors, connectorId]);

  useEffect(() => {
    let timeout;
    if (!connectorId && sortedConnectors && sortedConnectors.length > 0) {
      navigate(`${sortedConnectors[0].id}`);
    } else if (connectorId && connectorId !== 'new' && sortedConnectors) {
      const con = connectors.find((connector) => connector.id === connectorId);
      if (!con) {
        setTimeout(() => {
          navigate('/admin/connectors/new');
        }, 1000);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [sortedConnectors, connectorId]);

  //
  // useEffect(() => {
  //   if (connectorId !== 'new') {
  //     if (sortedConnectors && sortedConnectors.length > 0) {
  //       const con = connectors.find((connector) => connector.id === connectorId);
  //       if (!con) {
  //         navigate(`/admin/connectors`);
  //       }
  //     } else {
  //       navigate(`/admin/connectors/new`);
  //     }
  //   }
  // }, [sortedConnectors, connectorId, connectors, navigate]);

  return (
    <div className={s.subcontent}>
      <div className={s.subsidebar}>
        <div className={s.header}> Connectors</div>
        <NavLink data-testid={'connector-add'} to={'new'} className={s.add}>
          + {t('admin:connectors.addConnector')}
        </NavLink>
        <nav className={clsx(s.section)}>
          {!sortedConnectors && (
            <Skeleton
              count={5}
              height={20}
              width={'calc(100% - 32px '}
              style={{ marginBottom: 28, marginLeft: 16 }}
            />
          )}
          {sortedConnectors?.map((connector) => (
            <NavLink
              data-testid={'connector-item'}
              ref={connectorId === connector.id ? connectorRef : null}
              key={connector.id}
              to={`${connector.id}`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {connector.name}
              {connector.type === 'sftp' && <ConnectorFTPIcon />}
              {connector.type === 'http' && <ConnectorRestIcon />}
              {connector.type === 'ms365' && <ConnectorM365Icon />}
            </NavLink>
          ))}
        </nav>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminConnectors;
