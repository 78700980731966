import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Ring } from '@uiball/loaders';
import clsx from 'clsx';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation, useParams } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { isPaperboxEmail, sleep, useKey, usePrevious } from '../../../../shared/helpers/helpers';
import { useModal } from '../../../../shared/hooks/useModal';
import {
  ActiveEntityPair,
  Bounds,
  DocumentDetails,
  DocumentEntity,
  EntityComplex,
  PaginatedToken,
  Token,
} from '../../../../shared/models/document';
import { UrlParams } from '../../../../shared/models/generic';
import {
  documentSlice,
  getRawPDF,
  isInteractiveSelector,
  pageIndexToPageNoSelector,
} from '../../../../shared/store/documentSlice';
import { editDocumentEntity, labelerSlice } from '../../../../shared/store/labelerSlice';
import { useDispatch, useSelector } from '../../../../shared/store/store';
import DocumentLabelerThumbs from '../DocumentLabelerThumbs';
import DocumentLabelerTablePanel from '../grid/DocumentLabelerTablePanel';
import LabelerPagination from '../LabelerPagination';
import DocumentLabelerToolbar from '../toolbar/DocumentLabelerToolbar';
import * as listeners from '../viewer/listeners';
import {
  createEntityFromBounds,
  createEntityFromSelection,
  doOverlap,
  getClippedCoordinates,
  getTableEntityFromSelection,
  getTokenBounds,
  normalizeBounds,
} from './helpers/helpers';
import s from './labeler-view.module.scss';
import { LabelerTable } from './LabelerTable';
import { LabelerToken } from './LabelerToken';

interface Props {
  documentDetails: DocumentDetails;
  handleDelete: (entityPair: ActiveEntityPair, event?: any) => void;
  handlePrevious: () => void;
  handleNext: () => void;
}

const DocumentLabelerView: React.FC<Props> = ({
  documentDetails,
  handleDelete,
  handlePrevious,
  handleNext,
}) => {
  const { inboxId, docId }: UrlParams = useParams();

  const dispatch = useDispatch();
  const imgRef = useRef();
  const activeEntityRef = useRef();
  const cursorLayerRef = useRef();
  const location = useLocation();
  const historical = location.pathname.includes('historical');
  const {
    width: pageWidth,
    height: pageHeight,
    ref: pageRef,
  } = useResizeDetector({
    refreshRate: 1,
    refreshMode: 'throttle',
  });

  const { height: innerHeight, ref: containerRef } = useResizeDetector({
    refreshRate: 1,
    refreshMode: 'throttle',
  });

  const { labelingMode } = useSelector((state) => state.inbox.currentInbox.settings);
  const userAccount = useSelector((state) => state.user.userAccount);
  const documentJSON = useSelector((state) => state.document.documentJSON);
  const isImageLoading = useSelector((state) => state.document.isImageLoading);
  const pageImagesMap = useSelector((state) => state.document.pageImagesMap);
  const isViewerLoaded = useSelector((state) => state.document.isViewerLoaded);

  const pageIndexMap = useSelector(pageIndexToPageNoSelector);
  const activePageNo = useSelector((state) => state.labeler.activePageNo);
  const activeEntityPair = useSelector((state) => state.labeler.activeEntityPair);
  const activeTool = useSelector((state) => state.labeler.activeTool);
  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const tempEntity = useSelector((state) => state.labeler.tempEntity);
  const tableEditActive = useSelector((state) => state.labeler.tableEditActive);
  const isThumbsVisible = useSelector((state) => state.labeler.isThumbsVisible);
  const activeCell = useSelector((state) => state.labeler.activeCell);
  const editingTableEntity = useSelector((state) => state.labeler.editingTableEntity);
  const isInteractive = useSelector(isInteractiveSelector);

  const { isModalOpen, isDialogOpen } = useModal();

  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [currentSelection, setCurrentSelection] = useState<Bounds | null>(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [pageWidthScale, setPageWidthScale] = useState(70);
  const [rotateState, setRotateState] = useState<number>(0);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [uriMapping, setUriMapping] = useState({});
  const [isCtrlDown, setIsCtrlDown] = useState(false);
  const [isShiftDown, setIsShiftDown] = useState(false);
  const [isSpaceDown, setIsSpaceDown] = useState(false);
  const [activeEntity, setActiveEntity] = useState<DocumentEntity>();
  const prevRotateState = usePrevious(rotateState);
  const prevActivePageNo = usePrevious(activePageNo);

  useEffect(() => {
    if (!documentDetails) {
      dispatch(documentSlice.actions.setIsViewerLoaded(false));
      setPageWidthScale(70);
    }
  }, [dispatch, documentDetails]);

  useEffect(() => {
    const current = pageImagesMap[docId];
    dispatch(labelerSlice.actions.setIsThumbsVisible(!!current));
  }, [dispatch, docId, pageImagesMap]);

  useEffect(() => {
    if (!activeEntityPair) {
      dispatch(labelerSlice.actions.setEditingTableEntity(null));
    }
  }, [activeEntityPair]);
  const handleSetPage = useCallback(
    (newPageNo: number) => {
      if (!newPageNo) return;
      const boundPageNo = Math.max(0, Math.min(pageIndexMap[pageIndexMap.length - 1], newPageNo));
      if (boundPageNo !== activePageNo) {
        dispatch(documentSlice.actions.setIsViewerLoaded(false));
        dispatch(labelerSlice.actions.setActiveEntityPair(null));
        dispatch(labelerSlice.actions.setActivePageNo(boundPageNo));
      }
    },
    [activePageNo, dispatch, pageIndexMap]
  );
  const handleNextPage = () =>
    handleSetPage(pageIndexMap[pageIndexMap.findIndex((e) => e === activePageNo) + 1]);
  const handlePrevPage = () =>
    handleSetPage(pageIndexMap[pageIndexMap.findIndex((e) => e === activePageNo) - 1]);

  const image = useMemo(() => {
    const current = pageImagesMap[docId];
    return current ? current[activePageNo]?.imageString : null;
  }, [activePageNo, docId, pageImagesMap]);

  const activePageDimensions = useMemo(() => {
    if (documentDetails?.dimensions) return documentDetails?.dimensions[activePageNo - 1];
    return { height: 0, width: 0 };
  }, [activePageNo, documentDetails?.dimensions]);

  // bundle_page_no ipv document dimensions op te bepalen welke document images er worden gerendered ,

  const createTempEntity = (token: Token) => {
    const e = createEntityFromBounds(getTokenBounds(token), token.text, activePageNo);
    dispatch(labelerSlice.actions.setTempEntity(e));
  };

  const handleTokenClick = (token: Token) => {
    createTempEntity(token);
    dispatch(labelerSlice.actions.setActiveEntityPair(null));
  };
  const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const onMouseMove = (e) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    let x = e.clientX - bounds.x;
    let y = e.clientY - bounds.y;

    if (rotateState === 90) {
      x = e.clientY - bounds.y;
      y = bounds.width - (e.clientX - bounds.x);
    } else if (rotateState === 180) {
      x = bounds.width - (e.clientX - bounds.x);
      y = bounds.height - (e.clientY - bounds.y);
    } else if (rotateState === 270) {
      x = bounds.height - (e.clientY - bounds.y);
      y = e.clientX - bounds.x;
    }
    setPointerPosition({ x, y });
  };

  const onMouseDown = (e: React.PointerEvent<HTMLDivElement>) => {
    if (isSpaceDown) return;
    if (e.button !== 0) return;
    const cursorLayer = cursorLayerRef.current as HTMLDivElement;
    const pageLayer = pageRef.current as HTMLDivElement;
    cursorLayer.onpointermove = onPointerMove;
    cursorLayer.setPointerCapture(e.pointerId);
    const bounds = pageLayer.getBoundingClientRect();
    dispatch(labelerSlice.actions.setActiveEntityPair(null));

    let x1 = e.clientX - bounds.x;
    let y1 = e.clientY - bounds.y;

    if (rotateState === 90) {
      x1 = e.clientY - bounds.y;
      y1 = bounds.width - (e.clientX - bounds.x);
    } else if (rotateState === 180) {
      x1 = bounds.width - (e.clientX - bounds.x);
      y1 = bounds.height - (e.clientY - bounds.y);
    } else if (rotateState === 270) {
      x1 = bounds.height - (e.clientY - bounds.y);
      y1 = e.clientX - bounds.x;
    }
    const x2 = x1;
    const y2 = y1;

    setIsSelecting(true);
    setCurrentSelection({ x1, x2, y1, y2 });
  };
  const onPointerMove = useCallback(
    (e) => {
      const bounds = e.currentTarget.getBoundingClientRect();
      let x2 = e.clientX - bounds.x;
      let y2 = e.clientY - bounds.y;

      if (rotateState === 90) {
        x2 = e.clientY - bounds.y;
        y2 = bounds.width - (e.clientX - bounds.x);
      } else if (rotateState === 180) {
        x2 = bounds.width - (e.clientX - bounds.x);
        y2 = bounds.height - (e.clientY - bounds.y);
      } else if (rotateState === 270) {
        x2 = bounds.height - (e.clientY - bounds.y);
        y2 = e.clientX - bounds.x;
      }
      setCurrentSelection((selection) => {
        return {
          ...selection,
          x2,
          y2,
        };
      });
    },
    [rotateState]
  );
  const onMouseUp = (e) => {
    if (e.button !== 0 || isSpaceDown || !cursorLayerRef.current) return;
    const cursorLayer = cursorLayerRef.current as HTMLDivElement;
    cursorLayer.onpointermove = null;
    cursorLayer.releasePointerCapture(e.pointerId);

    if (!isSelectionVisible && activeTool !== 'default' && !isShiftDown) {
      setCurrentSelection(null);
      setIsSelecting(false);
      dispatch(labelerSlice.actions.setTempEntity(null));
      return;
    }
    let entity;
    // if (isShiftDown) {
    if (overlappingTokens.length === 1) {
      const uri = uriMapping[overlappingTokens[0].uriRef];
      if (uri) {
        if (
          Math.abs(scaledSelection.x1 - scaledSelection.x2) < 5 &&
          Math.abs(scaledSelection.y1 - scaledSelection.y2) < 5
        ) {
          const a = document.createElement('a');
          if (uri.includes('http')) {
            a.href = uri;
          } else {
            a.href = `https://${uri}`;
          }
          a.setAttribute('target', '_blank');
          a.setAttribute('id', 'temp_download_url');
          a.click();
          a.remove();
          return;
        }
      }
    }
    // }
    if (activeTool === 'default') {
      entity = createEntityFromSelection(scaledSelection, activePageNo, tokens);
    } else if (activeTool === 'rectangle') {
      const cCanvas = document.createElement('canvas');
      const sel = normSelection;
      const img = imgRef.current as HTMLImageElement;
      const scale = img.naturalHeight / img.height;
      const cX = sel.x1 * scale;
      const cY = sel.y1 * scale;
      const cWidth = (sel.x2 - sel.x1) * scale;
      const cHeight = (sel.y2 - sel.y1) * scale;
      cCanvas.width = cWidth / scale;
      cCanvas.height = cHeight / scale;
      const cropCtx = cCanvas.getContext('2d');
      cropCtx.drawImage(img, cX, cY, cWidth, cHeight, 0, 0, cWidth / scale, cHeight / scale);
      const imageUrl = cCanvas.toDataURL('image/jpeg', 0.7);
      entity = createEntityFromSelection(scaledSelection, activePageNo, null, null, false, imageUrl);
    } else if (activeTool === 'table') {
      entity = getTableEntityFromSelection(tokens, scaledSelection, activePageNo) as DocumentEntity;
    }

    dispatch(labelerSlice.actions.setTempEntity(entity));
    setCurrentSelection(null);
    setIsSelecting(false);
  };

  const pageScale = useMemo(() => {
    if (pageWidth && activePageDimensions && activePageDimensions.width > 0) {
      return pageWidth / activePageDimensions.width;
    }
    return 0.001;
  }, [activePageDimensions, pageWidth]);

  const prevScale = usePrevious(pageScale);

  const handleScale = useCallback(
    (bigger: boolean) => {
      if (bigger) {
        setPageWidthScale(Math.min(pageWidthScale + 10, 500));
      } else {
        setPageWidthScale(Math.max(pageWidthScale - 10, 30));
      }
    },
    [pageWidthScale]
  );

  const handleFitToScreen = useCallback(
    (fitType: 'all' | 'width' | 'height', forcedAngle?: number) => {
      const container = containerRef.current as HTMLDivElement;
      const cHeight = container.clientHeight;
      const cWidth = container.clientWidth;
      const angle = forcedAngle ?? rotateState;

      if (fitType === 'width') {
        if (angle === 90 || angle === 270) {
          const widthRatio = activePageDimensions.width / activePageDimensions.height;
          setPageWidthScale(100 * widthRatio);
        } else {
          setPageWidthScale(100);
        }
      } else {
        if (angle === 90 || angle === 270) {
          const widthRatio = cHeight / activePageDimensions.width;
          const rate = activePageDimensions.width / activePageDimensions.height;
          const correct = (widthRatio * activePageDimensions.height) / cWidth;
          setPageWidthScale(rate * correct * 100);
        } else {
          const widthRatio = (cHeight / activePageDimensions.height) * activePageDimensions.width;
          setPageWidthScale((widthRatio / cWidth) * 100);
        }
      }
    },
    [activePageDimensions, rotateState, containerRef]
  );

  const handleDownload = async () => {
    await dispatch(getRawPDF(documentDetails.id, inboxId)).then((res) => {
      const a = document.createElement('a');
      const filename = res.headers['content-disposition']?.match(/filename=(.+)/)[1];
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.setAttribute('target', '_blank');
      a.setAttribute('id', 'temp_download_url');
      a.click();
      a.remove();
    });
  };

  const handleRotate = () => {
    if (rotateState === 0) {
      setRotateState(90);
    } else if (rotateState === 90) {
      setRotateState(180);
    } else if (rotateState === 180) {
      setRotateState(270);
    } else {
      setRotateState(0);
    }
  };

  useEffect(() => {
    if (activePageNo !== prevActivePageNo) {
      setRotateState(0);
    }
  }, [activePageNo, prevActivePageNo]);

  const normSelection = useMemo(() => {
    if (currentSelection) {
      const norm = normalizeBounds(currentSelection);
      const { clippedX, clippedY } = getClippedCoordinates(norm.x2, norm.y2, pageWidth, pageHeight);
      return {
        x1: Math.max(0, norm.x1),
        y1: Math.max(0, norm.y1),
        x2: clippedX,
        y2: clippedY,
      };
    }
  }, [currentSelection, pageHeight, pageWidth]);

  const scaledSelection = useMemo(() => {
    if (normSelection) {
      return {
        x1: normSelection.x1 / pageScale,
        y1: normSelection.y1 / pageScale,
        x2: normSelection.x2 / pageScale,
        y2: normSelection.y2 / pageScale,
      };
    }
  }, [normSelection, pageScale]);

  useEffect(() => {
    if (documentJSON) {
      setTokens(documentJSON[activePageNo - 1]?.tokens || []);
      setUriMapping(documentJSON[activePageNo - 1]?.uriMapping ?? {});
    }
  }, [activePageNo, documentJSON]);

  const paginatedTokens = useMemo(() => {
    return documentJSON
      ?.map((page) => {
        return {
          tokens: page.tokens.map((t) => ({
            text: t.text,
            page: page.page.pageNo,
            id: `${t.x}r${t.y}l${t.width}t${t.height}b${t.text}`,
            index: 0,
          })),
        };
      })
      .reduce((prev, cur) => {
        return [...prev, ...cur.tokens].map((e, i) => ({ ...e, index: i }));
      }, []);
  }, [documentJSON]);

  const [activeSearchToken, setActiveSearchToken] = useState<PaginatedToken>();
  useEffect(() => {
    if (activeSearchToken) {
      handleSetPage(activeSearchToken.page - 1);
    }
  }, [activeSearchToken, handleSetPage]);

  const isSelectionVisible = useMemo(() => {
    return (
      (normSelection && Math.abs(normSelection?.y1 - normSelection?.y2) > 10) ||
      Math.abs(normSelection?.x1 - normSelection?.x2) > 10
    );
  }, [normSelection]);

  useEffect(() => {
    if (activeEntityPair) {
      const entity = documentEntities.find((e) => e.uuid === activeEntityPair.entityId);
      if (!entity || (!entity.valueLocations[0] && !entity.value['complex'])) return;
      let selectedPageNo = entity.pageNo;
      if (entity.value['complex'] && activeEntityPair.childId) {
        selectedPageNo = entity.value['complex'][activeEntityPair.childId]?.pageNo;
      }

      if (entity.valueLocations.length > 0) {
        selectedPageNo = entity.valueLocations[activeEntityPair.locationIndex].pageNo ?? entity.pageNo;
      }
      if (entity.valueLocations[activeEntityPair.locationIndex]?.pageNo) {
        selectedPageNo = entity.valueLocations[activeEntityPair.locationIndex]?.pageNo;
      }
      if (selectedPageNo !== activePageNo) {
        dispatch(documentSlice.actions.setIsViewerLoaded(false));
      }
      if (selectedPageNo !== 0) {
        dispatch(labelerSlice.actions.setActivePageNo(selectedPageNo));
        setActiveEntity(entity);
        // This sleep is here so the React setstate event triggers before getting the entity element.
        sleep(200).then(() => {
          const activeEntityElement = activeEntityRef.current as HTMLDivElement;
          activeEntityElement?.scrollIntoView({
            block: 'center',
            inline: 'center',
            behavior: 'smooth',
          });
        });
      }
    } else {
      setActiveEntity(null);
      dispatch(labelerSlice.actions.setActiveCell(null));
    }
  }, [activeEntityPair, activePageNo, dispatch, documentEntities]);

  const handleDelKey = useCallback(
    (e) => {
      if (e.key === 'Delete' && activeEntityPair?.entityId && documentDetails.action == null) {
        handleDelete(activeEntityPair);
      }
    },
    [activeEntityPair, documentDetails, handleDelete]
  );

  useEffect(() => {
    const container = containerRef.current as HTMLDivElement;
    if (container) {
      container.addEventListener('keydown', handleDelKey);
    }
    return () => {
      if (container) {
        container.removeEventListener('keydown', handleDelKey);
      }
    };
  }, [containerRef, handleDelKey]);

  const overlappingTokens = useMemo(() => {
    const tokenList: Token[] = [];

    if (tokens && scaledSelection) {
      const length = tokens.length;
      for (let i = 0; i < length; i++) {
        const tokenBound = getTokenBounds(tokens[i]);
        const doesOverlap = doOverlap(tokenBound, scaledSelection);
        if (doesOverlap) {
          tokenList.push(tokens[i]);
        }
      }
    }
    return tokenList;
  }, [scaledSelection, tokens]);

  const isEditable = useMemo(() => {
    return (
      documentDetails?.action == null &&
      documentDetails?.processed === true &&
      documentDetails?.docTypeId !== '@PB_DELETE'
    );
  }, [documentDetails]);

  const selectionStyle = useMemo(() => {
    return {
      top: normSelection?.y1,
      height: normSelection?.y2 - normSelection?.y1,
      left: normSelection?.x1,
      width: normSelection?.x2 - normSelection?.x1,
      pointerEvents: isSelecting ? 'none' : 'all',
    } as CSSProperties;
  }, [isSelecting, normSelection]);

  const containerStyle = useMemo(() => {
    const style: CSSProperties = {
      cursor: 'grab',
      opacity: isViewerLoaded && documentJSON ? 1 : 0,
      minWidth: `${pageWidthScale}%`,
      maxWidth: `${pageWidthScale}%`,
      transform: `rotate(${rotateState}deg)`,
      transition: isViewerLoaded
        ? 'opacity 0.3s ease-in-out, transform 0.3s, min-width 0.1s, max-width 0.1s'
        : 'unset',
    };
    return style;
  }, [documentJSON, isViewerLoaded, pageWidthScale, rotateState]);

  const imageStyle = useMemo(() => {
    let style: CSSProperties = {};
    if (!isViewerLoaded) style = { width: 0, height: 0, opacity: 0 };
    else if (pageScale && prevScale === 0.001) {
      style = {
        width: activePageDimensions.width * pageScale,
        height: activePageDimensions.height * pageScale,
      };
    }
    return style;
  }, [activePageDimensions, isViewerLoaded, pageScale, prevScale]);

  const entityStyle = useCallback(
    (entityCoords: Bounds) => {
      if (!entityCoords) return { top: -10, left: -10 };
      const style: CSSProperties = {
        top: entityCoords.y1 * pageScale,
        height: (entityCoords.y2 - entityCoords.y1) * pageScale - 4,
        left: entityCoords.x1 * pageScale,
        width: (entityCoords.x2 - entityCoords.x1) * pageScale - 4,
        pointerEvents: isSelecting ? 'none' : 'all',
      };
      return style;
    },
    [isSelecting, pageScale]
  );
  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const handleContainerMouseMove = (e) => {
    if (!isSpaceDown) return;

    const ele = containerRef.current as HTMLDivElement;
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;
    const maxTop = ele.scrollHeight - ele.clientHeight;
    const maxLeft = ele.scrollWidth - ele.clientWidth;
    ele.scrollTop = Math.min(Math.max(0, pos.top - dy), maxTop);
    ele.scrollLeft = Math.min(Math.max(0, pos.left - dx), maxLeft);

    if (ele.scrollLeft === 0) {
      pos.x = e.clientX;
      pos.left = ele.scrollLeft;
    }
    if (ele.scrollTop === 0) {
      pos.y = e.clientY;
      pos.top = ele.scrollTop;
    }

    if (ele.scrollLeft >= maxLeft) {
      pos.x = e.clientX;
      pos.left = ele.scrollLeft;
    }
    if (ele.scrollTop >= maxTop) {
      pos.y = e.clientY;
      pos.top = ele.scrollTop;
    }
  };

  const handleContainerMouseDown = (e) => {
    if (!isSpaceDown) return;
    const ele = containerRef.current as HTMLDivElement;
    const pageEle = pageRef.current as HTMLDivElement;
    pos = { left: ele.scrollLeft, top: ele.scrollTop, x: e.clientX, y: e.clientY };
    ele.style.cursor = 'grabbing';
    pageEle.style.cursor = 'grabbing';
    document.addEventListener('mousemove', handleContainerMouseMove);
    document.addEventListener('mouseup', handleContainerMouseUp);
  };

  const handleContainerMouseUp = () => {
    const ele = containerRef.current as HTMLDivElement;
    const pageEle = pageRef.current as HTMLDivElement;
    document.removeEventListener('mousemove', handleContainerMouseMove);
    document.removeEventListener('mouseup', handleContainerMouseUp);
    ele.style.cursor = 'grab';
    pageEle.style.cursor = 'pointer';
    ele.style.removeProperty('user-select');
  };

  useKey(
    ' ',
    () => setIsSpaceDown(true),
    () => setIsSpaceDown(false)
  );
  useKey(
    'Shift',
    () => setIsShiftDown(true),
    () => setIsShiftDown(false)
  );
  useKey(
    'Control',
    () => setIsCtrlDown(true),
    () => setIsCtrlDown(false)
  );

  useEffect(() => {
    const ele = containerRef.current as HTMLDivElement;
    const pageEle = pageRef.current as HTMLDivElement;
    if (!containerRef.current) return;
    if (isSpaceDown) {
      document.body.style.userSelect = 'none';
      ele.style.cursor = 'grab';
      pageEle.style.cursor = 'grab';
    } else {
      document.body.style.userSelect = 'unset';
      ele.style.cursor = 'default';
      pageEle.style.cursor = 'crosshair';
    }
  }, [containerRef, isSpaceDown, pageRef]);

  return (
    <>
      <listeners.ClipboardListener itemRef={containerRef} tempEntity={tempEntity} />
      <listeners.EscListener itemRef={containerRef} dispatch={dispatch} tempEntity={tempEntity} />
      <listeners.DocumentListener
        isInteractive={isInteractive}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        isModalOpen={isModalOpen || isDialogOpen}
      />
      <listeners.WheelListener
        isCtrlDown={isCtrlDown}
        handleScale={handleScale}
        containerRef={containerRef}
      />
      {isImageLoading && <TopBarProgress />}
      {!historical && <DocumentLabelerToolbar isLoading={!documentDetails || !isEditable} />}

      <div className={s.wrapper}>
        <div
          onMouseDown={handleContainerMouseDown}
          tabIndex={0}
          ref={containerRef}
          onClick={(e) => {
            if (e.target === e.currentTarget) dispatch(labelerSlice.actions.setActiveEntityPair(null));
          }}
          className={clsx(s.container, { [s.container__thin]: isThumbsVisible })}
        >
          <LabelerPagination
            setActiveToken={setActiveSearchToken}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            paginatedTokens={paginatedTokens}
            innerHeight={historical ? innerHeight - 55 : innerHeight}
            isMouseDown={isSelecting}
            isSelectionVisible={isSelectionVisible}
            handleSetPage={handleSetPage}
            handleRotate={handleRotate}
            handleScale={handleScale}
            handleFitToScreen={handleFitToScreen}
            handleDownload={handleDownload}
            handleSetThumbsVisible={() => dispatch(labelerSlice.actions.setIsThumbsVisible(!isThumbsVisible))}
          />
          {(!documentJSON || !isViewerLoaded) && (
            <div className={s.loading}>
              <Ring color={'#0085ff'} size={36} lineWeight={6} />
            </div>
          )}
          {documentJSON && isViewerLoaded && (
            <div
              style={{ position: 'absolute', top: -1000, width: 10, height: 10 }}
              data-testid={'checkthis'}
            />
          )}
          <div
            ref={pageRef}
            data-testid={'labeler'}
            style={containerStyle}
            data-tour={'labeler'}
            className={clsx(s.inner_container, {
              [s.inner_container__reset]: rotateState === 0 && prevRotateState === 270,
            })}
          >
            {isSelectionVisible && normSelection && <div style={selectionStyle} className={s.selection} />}

            <div className={s.annotation_layer}>
              <div
                onMouseMove={onMouseMove}
                ref={cursorLayerRef}
                onPointerDown={onMouseDown}
                onPointerUp={onMouseUp}
                className={s.cursor_layer}
              />

              {documentEntities?.map((entity) => {
                const entityType = entityTypes.find((et) => et.id === entity.type);
                if (entityType.type === 'complex') {
                  const complex = entity.value as EntityComplex;
                  return Object.entries(complex?.complex ?? {}).map(([k, v]) => {
                    if (v.valueLocations) {
                      if (v['pageNo'] !== activePageNo) return null;

                      const entityPosition = v.valueLocations[0];
                      return (
                        <div
                          onClick={() => {
                            dispatch(
                              labelerSlice.actions.setActiveEntityPair({
                                entityId: entity.uuid,
                                locationIndex: 0,
                                childId: k,
                              })
                            );
                          }}
                          key={k}
                          style={entityStyle(entityPosition)}
                          className={clsx(s.annotation, {
                            [s.annotation__active]:
                              activeEntityPair?.childId === k && activeEntityPair?.entityId === entity.uuid,
                          })}
                        ></div>
                      );
                    }
                  });
                }
                return entity?.valueLocations.map((vl, i) => {
                  if (!vl) return null;
                  const entityPosition = vl;
                  const isActive =
                    activeEntityPair?.entityId === entity.uuid && activeEntityPair?.locationIndex === i;
                  let isInActive = false;

                  if (labelingMode) {
                    isInActive = activeEntityPair ? !isActive : false;
                  }
                  let pageNo = entity?.pageNo;
                  if (vl.pageNo) pageNo = vl.pageNo;
                  if (activePageNo !== pageNo) return null;
                  if (typeof entity.value === 'object') {
                    return (
                      <LabelerTable
                        pageRef={pageRef}
                        key={entity.uuid + i}
                        tableEntity={isActive && editingTableEntity ? editingTableEntity : entity}
                        pageScale={pageScale}
                        tokens={tokens}
                        setIsActive={() =>
                          dispatch(
                            labelerSlice.actions.setActiveEntityPair({
                              entityId: entity.uuid,
                              locationIndex: i,
                            })
                          )
                        }
                        isActive={activeEntityPair && entity.uuid === activeEntityPair?.entityId}
                        isTableEditActive={tableEditActive && isActive}
                        activeCell={activeCell}
                        setTableEntity={(e) =>
                          dispatch(
                            labelerSlice.actions.setEditingTableEntity(e)
                            // dispatch(
                            //   editDocumentEntity(inboxId, entity, {
                            //     value: e.value,
                            //     valueLocations: e.valueLocations,
                            //   })
                          )
                        }
                      />
                    );
                  }
                  return (
                    <div
                      key={entity.uuid + i}
                      ref={isActive ? activeEntityRef : null}
                      onClick={() => {
                        dispatch(
                          labelerSlice.actions.setActiveEntityPair({
                            entityId: entity.uuid,
                            locationIndex: i,
                          })
                        );
                      }}
                      className={clsx(
                        s.annotation,
                        { [s.annotation__active]: isActive },
                        { [s.annotation__faded]: isInActive },
                        {
                          [s.annotation__source_user]:
                            labelingMode && userAccount?.isLabeler && entity.source === 'user',
                        },
                        {
                          [s.annotation__source_model]:
                            labelingMode && userAccount?.isLabeler && entity.source === 'PAPERBOX',
                        },
                        {
                          [s.annotation__suggestion]:
                            entity.isSuggestion && isPaperboxEmail(userAccount.email),
                        }
                      )}
                      style={entityStyle(entityPosition)}
                    />
                  );
                });
              })}
              {tempEntity &&
                (typeof tempEntity.value === 'object' ? (
                  <LabelerTable
                    pageRef={pageRef}
                    key={'tempTable'}
                    tableEntity={tempEntity}
                    pageScale={pageScale}
                    isTempEntity
                    tokens={tokens}
                    isActive
                    isTableEditActive={tableEditActive}
                    activeCell={activeCell}
                    setTableEntity={(e) => dispatch(labelerSlice.actions.setTempEntity(e))}
                  />
                ) : (
                  <div
                    onClick={() => dispatch(labelerSlice.actions.setTempEntity(null))}
                    className={clsx(s.annotation, s.annotation__temp)}
                    style={entityStyle(tempEntity?.valueLocations[0])}
                  />
                ))}

              {tokens?.map((t) => {
                return (
                  <LabelerToken
                    activeSearchToken={activeSearchToken}
                    searchQuery={searchQuery}
                    pointerPosition={pointerPosition}
                    handleTokenMouseDown={onMouseDown}
                    handleTokenClick={() => handleTokenClick(t)}
                    token={t}
                    isSelecting={isSelecting}
                    pageScale={pageScale}
                    overlappingTokens={overlappingTokens}
                    key={`${t.x}r${t.y}l${t.width}t${t.height}b${t.text}`}
                    activeTool={activeTool}
                  />
                );
              })}
            </div>

            <img
              data-hj-suppress
              onLoad={() => dispatch(documentSlice.actions.setIsViewerLoaded(true))}
              style={imageStyle}
              loading={'eager'}
              draggable={false}
              className={clsx({ [s.image]: isViewerLoaded }, 'squeaky-hide')}
              ref={imgRef}
              src={image}
              alt=""
            />
          </div>
        </div>
        <DocumentLabelerThumbs />
      </div>
      {(tempEntity?.value['columns'] || activeEntity?.value['columns']) && (
        <DocumentLabelerTablePanel
          updateEntity={(updatedEntity) => {
            if (tempEntity) {
              dispatch(labelerSlice.actions.setTempEntity(updatedEntity));
            } else {
              dispatch(editDocumentEntity(inboxId, activeEntity.uuid, { value: updatedEntity.value }));
            }
          }}
          tableEntity={tempEntity ? tempEntity : activeEntity}
        />
      )}
    </>
  );
};

export default DocumentLabelerView;
