import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import Document from '../components/document/Document';
import { usePrevious } from '../shared/helpers/helpers';
import { useQuery } from '../shared/hooks/useQuery.tsx';
import { DocumentDetails } from '../shared/models/document';
import { UrlParams } from '../shared/models/generic';
import { DocTypeSummary } from '../shared/models/inbox';
import documentSlice, {
  getAllowedFields,
  getDocument,
  getNextSiblingId,
  getPageImage,
  getPrevSiblingId,
  getJSON,
  patchRealtimeLock,
  clearLock,
  clearDocumentSubs,
  getPageImageThumbs,
  realtimeLockInterval,
  getHistoricalSiblings,
} from '../shared/store/documentSlice';
import {
  clearLockListener,
  listenForActiveInbox,
  getMasterDataTables,
  listenForActionTypes,
  listenForDocTypes,
  listenForEntityTypes,
  listenForMetadata,
  listenForTagTypes,
  patchDocument,
  setLockListener,
  listenForApprovalChecks,
  listenForPendingDocs,
} from '../shared/store/inboxSlice';
import { labelerSlice } from '../shared/store/labelerSlice';
import { useDispatch, useSelector } from '../shared/store/store';
import { unsubMasterdata } from '../shared/store/subsSlice';

TopBarProgress.config({
  barThickness: 2,
  barColors: {
    '0': '#0037ff',
    '1.0': '#00AAFF',
  },
});

export let hasLocked;

interface Props {
  historical?: boolean;
}

const DocumentContainer: React.FC<Props> = ({ historical }) => {
  const { docId, inboxId }: UrlParams = useParams();
  const { docTypeId, subTypeId } = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeDocument = useSelector((state) => state.document.activeDocument);
  const userAccount = useSelector((state) => state.user.userAccount);
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const documentCounts = useSelector((state) => state.inbox.documentCounts);
  const currentInbox = useSelector((state) => state.inbox.currentInbox);
  const activePageNo = useSelector((state) => state.labeler.activePageNo);
  const prevDocumentId = useSelector((state) => state.document.prevDocumentId);
  const nextDocumentId = useSelector((state) => state.document.nextDocumentId);
  const isImageLoading = useSelector((state) => state.document.isImageLoading);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);
  const allowedEntityTypes = useSelector((state) => state.document.allowedEntityTypes);
  const lockedList = useSelector((state) => state.document.lockedList);
  const lockListener = useSelector((state) => state.document.lockListener);
  const copySub = useSelector((state) => state.subs.copySub);
  const metadataSub = useSelector((state) => state.subs.metadataSub);
  const docTypesSub = useSelector((state) => state.subs.docTypesSub);
  const prevSiblingIdSub = useSelector((state) => state.subs.prevSiblingIdSub);
  const nextSiblingIdSub = useSelector((state) => state.subs.nextSiblingIdSub);

  const [listenersLoaded, setListenersLoaded] = useState(false);

  const prevActive: DocumentDetails = usePrevious(document);
  const [unSub, setUnSub] = useState(undefined);

  const [shouldSkip, setShouldSkip] = useState(false);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [entityTypesLoaded, setEntityTypesLoaded] = useState(false);

  useEffect(() => {
    setUnSub(() => dispatch(getDocument(docId)));
  }, [dispatch, docId, allowedEntityTypes]);

  useEffect(() => {
    if (lockListener) setListenersLoaded(true);
  }, [lockListener]);

  useEffect(() => {
    if (activeDocument) {
      setIsDocumentLoaded(true);
    } else {
      setIsDocumentLoaded(false);
      setIsFetchingThumbs(false);
    }
  }, [activeDocument]);

  useEffect(() => {
    if (docId) {
      dispatch(documentSlice.actions.setActiveDocId(docId));
      dispatch(getJSON(docId, inboxId));
    } else {
      dispatch(documentSlice.actions.setActiveDocId(null));
    }
  }, [dispatch, docId, inboxId]);

  const [isFetchingThumbs, setIsFetchingThumbs] = useState(false);

  useEffect(() => {
    if (isDocumentLoaded && !isFetchingThumbs && !isImageLoading) {
      setIsFetchingThumbs(true);
      dispatch(getPageImageThumbs(docId, inboxId));
    }
  }, [isDocumentLoaded, docId, inboxId, dispatch, isFetchingThumbs, isImageLoading]);

  useEffect(() => {
    if (docId) {
      dispatch(getPageImage(docId, inboxId, activePageNo));
    }
  }, [activePageNo, dispatch, docId, inboxId]);

  useEffect(() => {
    return () => {
      if (unSub) {
        unSub();
      }
    };
  }, [unSub]);

  useEffect(() => {
    return () => {
      if (prevSiblingIdSub) prevSiblingIdSub();
    };
  }, [prevSiblingIdSub]);

  useEffect(() => {
    return () => {
      if (nextSiblingIdSub) nextSiblingIdSub();
    };
  }, [nextSiblingIdSub]);

  useEffect(() => {
    return () => {
      if (docTypesSub) {
        docTypesSub();
      }
    };
  }, [docTypesSub]);

  useEffect(() => {
    return () => {
      if (metadataSub) {
        metadataSub();
      }
    };
  }, [metadataSub]);
  useEffect(() => {
    if (!documentCounts) {
      dispatch(listenForActiveInbox(inboxId));
    }
  }, [dispatch, documentCounts, inboxId]);

  useEffect(() => {
    if (currentInbox && currentInbox.id != null) {
      dispatch(listenForPendingDocs());
      dispatch(listenForDocTypes());
      dispatch(listenForApprovalChecks());
      dispatch(listenForMetadata());
      dispatch(listenForActionTypes());
      dispatch(listenForEntityTypes());
      dispatch(listenForTagTypes());
    }
  }, [dispatch, currentInbox]);

  useEffect(() => {
    if (inboxId) {
      dispatch(getMasterDataTables(inboxId));
    }
  }, [dispatch, inboxId]);

  useEffect(() => {
    return () => {
      dispatch(unsubMasterdata());
    };
  }, [dispatch]);

  useEffect(() => {
    hasLocked = false;

    return () => {
      dispatch(clearDocumentSubs());
      dispatch(clearLock());
      dispatch(documentSlice.actions.setActiveDocument(null));
      dispatch(documentSlice.actions.setNextDocumentId(null));
      dispatch(documentSlice.actions.setPrevDocumentId(null));
      dispatch(documentSlice.actions.setDocumentJSON(null));
      dispatch(documentSlice.actions.setSelectedCopyId(null));
      dispatch(labelerSlice.actions.setActiveEntityPair(null));
      dispatch(labelerSlice.actions.setActivePageNo(1));
      dispatch(documentSlice.actions.setMasterDataResults(null));
      dispatch(documentSlice.actions.setCopyStructure({}));
      dispatch(documentSlice.actions.setAllowedEntityTypes([]));
      dispatch(documentSlice.actions.setDocTypeCategories(null));
      dispatch(documentSlice.actions.setDocumentMapImage({ image: null, docId }));
    };
  }, [dispatch, docId, inboxId]);

  useEffect(() => {
    if (allowedEntityTypes && allowedEntityTypes.length > 0) {
      setEntityTypesLoaded(true);
    } else {
      setEntityTypesLoaded(false);
    }
  }, [allowedEntityTypes]);

  useEffect(() => {
    if (activeDocument?.docTypeId !== prevActive?.docTypeId) {
      setEntityTypesLoaded(false);
    }
  }, [activeDocument, prevActive]);

  useEffect(() => {
    if (
      docTypeSettings.length > 0 &&
      entityTypes.length > 0 &&
      activeDocument?.docTypeId !== prevActive?.docTypeId &&
      !entityTypesLoaded
    ) {
      dispatch(getAllowedFields(activeDocument.docTypeId));
    }
  }, [
    activeDocument?.docTypeId,
    prevActive?.docTypeId,
    entityTypesLoaded,
    docTypeSettings.length,
    entityTypes.length,
    dispatch,
  ]);

  useEffect(() => {
    if (
      docId &&
      listenersLoaded &&
      userAccount &&
      userAccount.email &&
      !userAccount.isHidden &&
      !historical
    ) {
      dispatch(
        patchDocument(docId, inboxId, {
          last_opened_date: new Date(),
        })
      );
      dispatch(patchRealtimeLock(docId));
    }
  }, [dispatch, docId, inboxId, listenersLoaded, userAccount, historical]);

  useEffect(() => {
    if (inboxId && !historical) dispatch(setLockListener(inboxId));

    return () => {
      dispatch(clearLockListener());
    };
  }, [dispatch, inboxId, historical]);

  useEffect(() => {
    if (lockedList && docId && !historical) {
      const obj = lockedList[docId];
      if (obj == null) return;
      if (obj.email !== userAccount.email) {
        setShouldSkip(true);
      } else {
        setShouldSkip(false);
      }
    }
  }, [lockedList, docId, userAccount.email, historical]);

  useEffect(() => {
    return () => {
      dispatch(documentSlice.actions.setNextDocumentId(null));
      dispatch(documentSlice.actions.setPrevDocumentId(null));
      setShouldSkip(false);
    };
  }, [dispatch, docId, inboxId]);

  useEffect(() => {
    if (isDocumentLoaded && documentCounts) {
      if (!historical && lockedList) {
        dispatch(getNextSiblingId(inboxId, docTypeId));
        dispatch(getPrevSiblingId(inboxId, docTypeId));
      }
      if (historical) {
        dispatch(getHistoricalSiblings(inboxId));
      }
    }
  }, [historical, dispatch, docId, isDocumentLoaded, documentCounts, inboxId, lockedList, docTypeId]);

  useEffect(() => {
    return () => {
      if (realtimeLockInterval) clearInterval(realtimeLockInterval);
    };
  }, []);

  const parsedDocType = useMemo(() => {
    return { docTypeId, subTypeId } as DocTypeSummary;
  }, [docTypeId, subTypeId]);

  const handleNext = useCallback(
    (mustReplace?: boolean) => {
      if (nextDocumentId) {
        if (copySub) copySub();
        dispatch(documentSlice.actions.setActiveDocument(null));
        dispatch(labelerSlice.actions.setActivePageNo(1));
        dispatch(labelerSlice.actions.setActiveEntityPair(null));
        dispatch(documentSlice.actions.setIsViewerLoaded(false));
        dispatch(documentSlice.actions.setDocumentJSON(null));
        if (mustReplace) {
          navigate(window.location.pathname.replace(docId, nextDocumentId) + window.location.search, {
            replace: true,
          });
        } else {
          navigate(window.location.pathname.replace(docId, nextDocumentId) + window.location.search);
        }
      }
    },
    [copySub, dispatch, docId, navigate, nextDocumentId]
  );
  useEffect(() => {
    if (nextDocumentId && shouldSkip) {
      handleNext();
    }
  }, [handleNext, nextDocumentId, shouldSkip]);

  const handlePrevious = useCallback(
    (mustReplace?: boolean) => {
      if (prevDocumentId) {
        if (copySub) copySub();
        dispatch(labelerSlice.actions.setActivePageNo(1));
        dispatch(documentSlice.actions.setActiveDocument(null));
        dispatch(labelerSlice.actions.setActiveEntityPair(null));
        dispatch(documentSlice.actions.setIsViewerLoaded(false));
        dispatch(documentSlice.actions.setDocumentJSON(null));

        if (mustReplace) {
          navigate(window.location.pathname.replace(docId, prevDocumentId) + window.location.search, {
            replace: true,
          });
        } else {
          navigate(window.location.pathname.replace(docId, prevDocumentId) + window.location.search);
        }
      }
    },
    [copySub, dispatch, docId, prevDocumentId, navigate]
  );
  // useEffect(() => {
  //   if (userAccount?.email && intercomHash) {
  //     boot({
  //       languageOverride: userAccount.preferences.language ?? null,
  //       email: userAccount.email,
  //       actionColor: '#0085FF',
  //       backgroundColor: '#0085FF',
  //       userHash: intercomHash,
  //     });
  //   }
  // }, [boot, userAccount, intercomHash]);

  return (
    <Document
      historical={historical}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      routeDocType={parsedDocType}
    />
  );
};
export default DocumentContainer;
