// HTTP specific
import { mapRowsToObj } from '../../../components/admin/helpers/helpers.ts';
import { WebhookServerSideValue } from '../../models/admin.ts';
import { createWebhookField } from '../helpers.ts';
import { connectorRawToClient, IClientConnector, IRawConnector } from './connector.ts';

export interface WebhookRow {
  key: string;
  tempLocked: boolean;
  locked: boolean;
  error?: { key: string; msg: string };
  value: { content: string; type: string };
  skip?: boolean;
  markedForDelete?: boolean;
}

export interface IRawHTTPEndpoint {
  id?: string;
  connector_id: string;
  variables?: string[];
  path: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params: Record<string, any>;
  headers: Record<string, any>;
  payload: Record<string, any>; // Deperecated
  scope_params?: Record<string, any>;
}

// SFTP specific
interface IRawSFTPEndpoint {
  id?: string;
  connector_id: string;
  location?: string;
  filename?: string;
  payload: Record<string, any>;
  scope_params?: Record<string, any>;
}

// Combined type for all possible connectors
export type IRawEndpoint = IRawSFTPEndpoint | IRawHTTPEndpoint;
export interface IClientBaseEndpoint {
  id: string;
  scopeParams?: Record<string, any>;
}

export interface IClientEndpointHTTP extends IClientBaseEndpoint {
  connector: IClientConnector;
  params?: WebhookRow[];
  useDefaultPayload?: boolean;
  payload?: WebhookRow[];
  headers?: WebhookRow[];
  path: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}

export interface IClientEndpointSFTP extends IClientBaseEndpoint {
  connector: IClientConnector;
  location: string;
  filename: string;
  payload: WebhookRow[];
}
export type IClientEndpoint = IClientEndpointHTTP | IClientEndpointSFTP;

export const endpointRawToClient = (
  rawEndpoint: IRawEndpoint,
  connectors: IRawConnector[],
  serverSideValues: WebhookServerSideValue[]
): IClientEndpoint => {
  const rawConnector = connectors.find((e) => e.id === rawEndpoint.connector_id);
  const connector = connectorRawToClient(rawConnector, serverSideValues);

  if (connector?.type === 'http') {
    const httpEndpoint = rawEndpoint as IRawHTTPEndpoint;
    const payload = createWebhookField(httpEndpoint, 'payload', serverSideValues, false);
    return {
      id: httpEndpoint.id,
      connector: connector,
      path: httpEndpoint.path,
      params: createWebhookField(httpEndpoint, 'params', serverSideValues, false),
      headers: createWebhookField(httpEndpoint, 'headers', serverSideValues, false),
      payload: payload,
      useDefaultPayload: payload?.some((e) => e.key === '.psv') ?? true,
      method: httpEndpoint.method,
      scopeParams: rawEndpoint.scope_params,
    };
  } else if (connector?.type === 'sftp') {
    const sftpEndpoint = rawEndpoint as IRawSFTPEndpoint;
    return {
      id: sftpEndpoint.id,
      connector: connector,
      location: sftpEndpoint.location ?? '',
      filename: sftpEndpoint.filename ?? '',
      payload: createWebhookField(sftpEndpoint, 'payload', serverSideValues, false),
      scopeParams: rawEndpoint.scope_params,
    };
  }
};

export const endpointClientToRaw = (clientEndpoint: IClientEndpoint): IRawEndpoint => {
  if (clientEndpoint.connector.type === 'http') {
    const httpEndpoint = clientEndpoint as IClientEndpointHTTP;
    let payload = mapRowsToObj(httpEndpoint.payload);
    if (httpEndpoint.useDefaultPayload) {
      payload = { '.psv': '@PB_JSON_PAYLOAD' };
    }
    const obj: IRawHTTPEndpoint = {
      connector_id: httpEndpoint.connector.id,
      params: mapRowsToObj(httpEndpoint.params),
      path: httpEndpoint.path === '' ? '/' : httpEndpoint.path,
      headers: mapRowsToObj(httpEndpoint.headers),
      payload: payload,
      method: httpEndpoint.method,
    };
    if (clientEndpoint.scopeParams) obj.scope_params = clientEndpoint.scopeParams;
    return obj;
  } else if (clientEndpoint.connector.type === 'sftp') {
    const sftpEndpoint = clientEndpoint as IClientEndpointSFTP;
    const obj: IRawSFTPEndpoint = {
      connector_id: sftpEndpoint.connector.id,
      location: sftpEndpoint.location,
      filename: sftpEndpoint.filename,
      payload: mapRowsToObj(sftpEndpoint.payload),
    };
    if (clientEndpoint.scopeParams) obj.scope_params = clientEndpoint.scopeParams;
    return obj;
  }
};
