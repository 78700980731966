import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as CheckmarkIcon } from '../../../../shared/assets/svg/checkmark-icon.svg';
import { statusToIcon } from '../../../../shared/helpers/HelperComponents.tsx';
import { ApprovalCheckStatus } from '../../../../shared/models/document';
import { patchDocument, patchTopology } from '../../../../shared/store/inboxSlice.ts';
import { editDocumentEntity, labelerSlice } from '../../../../shared/store/labelerSlice.ts';
import { useDispatch, useSelector } from '../../../../shared/store/store';
import s from '../../../../shared/styles/component/document/document-labeler-sidebar-footer.module.scss';
import Tooltip from '../../../shared/tooltip/Tooltip';

interface Props {
  checks: {
    name: string;
    description: string;
    status: ApprovalCheckStatus;
    id: string;
  }[];
  occurrencesCounter?: Record<string, { current: number; min: number; max: number }>;
}

const DocumentLabelerSideChecks: React.FC<Props> = ({ checks }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { inboxId } = useParams();
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const copyStructure = useSelector((state) => state.document.copyStructure);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);

  const activeDocType = useMemo(() => {
    return docTypeSettings?.find((dt) => dt.docTypeId === activeDocument?.docTypeId);
  }, [activeDocument?.docTypeId, docTypeSettings]);

  const checkNameMapping = useCallback(
    (check: any) => {
      const name = t(`document:approvalChecks.${check.id}` as any, { defaultValue: check.name });
      const description = t(`document:approvalChecks.${check.id}Description` as any, {
        defaultValue: check.description,
      });
      return { name, description };
    },
    [t]
  );

  const occurrencesCounterItems: {
    id: string;
    status: ApprovalCheckStatus;
    name: string;
    current: number;
    min: number;
    max: number;
  }[] = useMemo(() => {
    if (!activeDocument || !documentEntities) return [];
    const counter: Record<string, any> = {};
    activeDocType?.settings?.entityTypes.forEach((et) => {
      counter[et.id] = { current: 0, min: et.minOccurrences ?? 0, max: et.maxOccurrences };
    });

    documentEntities.forEach((et) => {
      counter[et.type]?.current
        ? (counter[et.type].current += 1)
        : (counter[et.type] = { ...counter[et.type], current: 1 });
    });
    const list = Object.entries(counter).map(([key, value]) => {
      const isValid = value.current >= value.min && value.current <= (value.max ?? Infinity);
      const entityTypeDetails = entityTypes.find((e) => e.id === key);

      return { id: key, ...value, status: isValid ? 'succeeded' : 'failed', name: entityTypeDetails?.name };
    });
    return list;
  }, [documentEntities, activeDocument, activeDocType, entityTypes]);

  const classificationOccurrenceItems = useMemo(() => {
    if (!activeDocument) return [];
    const list = [];
    const docTypeDetails = docTypeSettings.find((e) => e.docTypeId === activeDocument.docTypeId);
    let mainStatus = 'succeeded';
    if (activeDocument.confidence < docTypeDetails?.settings.approvalThreshold) {
      mainStatus = 'warning';
    }
    if (activeDocument.docTypeId === '@PB_NOTYPE') mainStatus = 'failed';

    list.push({ name: 'Bundle', status: mainStatus });
    if (activeDocument.topology?.parts) {
      activeDocument.topology.parts.forEach((part) => {
        const docTypeDetails = docTypeSettings.find((e) => e.docTypeId === part.docTypeId);

        let status = 'succeeded';
        if (part.confidence < docTypeDetails?.settings.approvalThreshold) {
          status = 'warning';
        }
        if (part.docTypeId === '@PB_NOTYPE') status = 'failed';

        const capitalizedName = part.topologyType.charAt(0).toUpperCase() + part.topologyType.slice(1);
        const firstPage = part.pages[0].bundlePageNo;
        const lastPage = part.pages[part.pages.length - 1].bundlePageNo;
        let name = `${capitalizedName} (${t('document:approvalChecks.page')} ${firstPage} - ${lastPage})`;
        if (firstPage === lastPage) {
          name = `${capitalizedName} (${t('document:approvalChecks.page')} ${firstPage})`;
        }
        list.push({ name, status, firstPage, partId: part.id });
      });
    }
    if (list.length === 1) {
      return [];
    }
    return list;
  }, [activeDocument, docTypeSettings, t]);

  return (
    <div className={s.checks_rows}>
      {checks.map((check) => {
        if (check.status == 'info') return null;
        const { name, description } = checkNameMapping(check);

        let list = [];
        if (check.id === 'classificationConfidence') {
          list = classificationOccurrenceItems;
        }
        if (check.id === 'entityOccurrences') {
          list = occurrencesCounterItems;
        }
        let content;

        const handleManualOverride = (partId?: string) => {
          if (check.id === 'classificationConfidence') {
            const isMutation = copyStructure.originalDoc.id !== activeDocument.id;
            if (!partId) {
              dispatch(patchDocument(activeDocument.id, inboxId, { confidence: 1 }, isMutation));
            } else {
              dispatch(patchTopology(activeDocument.id, inboxId, { confidence: 1 }, partId, isMutation));
            }
          }
          if (check.id === 'ocrConfidence') {
            activeDocument.entities.forEach((ent) => {
              if (activeDocType.settings.ocrThreshold > ent.ocrConfidence) {
                dispatch(editDocumentEntity(inboxId, ent.uuid, { ocrConfidence: 1 }));
              }
            });
          }
        };

        if (list.length > 0) {
          if (check.id === 'entityOccurrences') {
            const succeededItems = list.filter((li) => li.status === 'succeeded').length;
            const allSucceeded = succeededItems === list.length;
            const hasVisibleList = list.length > 0 && !allSucceeded;
            content = (
              <div
                key={check.id}
                className={s.checks_row_group}
                data-testid={'sidebar-check-entity-occurrences'}
              >
                <div
                  className={clsx(s.checks_row, {
                    [s.checks_row__checked]: check.status === 'succeeded',
                    [s.checks_row__head]: hasVisibleList,
                  })}
                >
                  <span>{name}</span>
                  <div className={s.checks_icon}>{statusToIcon(check.status)}</div>
                </div>
                {hasVisibleList &&
                  list.map((checkItem) => {
                    const denominator =
                      checkItem.max && checkItem.current > checkItem.max ? checkItem.max : checkItem.min;
                    if (
                      (checkItem.max && checkItem.current > checkItem.max) ||
                      (checkItem.min && checkItem.current < checkItem.min)
                    ) {
                      return (
                        <div
                          key={checkItem.name}
                          className={clsx(s.checks_row, {
                            [s.checks_row__checked]: checkItem.status === 'succeeded',
                          })}
                        >
                          <span>{checkItem.name}</span>
                          <div className={s.checks_label}>
                            {checkItem.current}/{denominator}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            );
          } else {
            const succeededItems = list.filter((li) => li.status === 'succeeded').length;
            const allSucceeded = succeededItems === list.length;
            const hasVisibleList = list.length > 0 && !allSucceeded;
            content = (
              <div key={check.id} className={s.checks_row_group} data-testid={'sidebar-check-classification'}>
                <div
                  className={clsx(s.checks_row, {
                    [s.checks_row__checked]: check.status === 'succeeded',
                    [s.checks_row__head]: hasVisibleList,
                  })}
                >
                  <span>{name}</span>
                  {check.status === 'succeeded' && (
                    <div className={s.checks_icon}>{statusToIcon(check.status)}</div>
                  )}
                  {check.status !== 'succeeded' && (
                    <div
                      className={clsx(s.checks_label, {
                        [s.checks_label__warning]: check.status === 'warning',
                      })}
                    >
                      {`${succeededItems} / ${list.length}`}
                    </div>
                  )}
                </div>
                {hasVisibleList &&
                  list.map((checkItem, i) => {
                    const success = checkItem.status === 'succeeded';
                    return (
                      <div
                        key={checkItem.name}
                        className={clsx(s.checks_row_wrapper, { [s.interactive]: !success })}
                      >
                        <div
                          className={clsx(
                            s.checks_row,
                            {
                              [s.checks_row__checked]: success,
                            },
                            { [s.selectable]: i !== 0 && !success }
                          )}
                          onClick={() => {
                            if (checkItem.firstPage)
                              dispatch(labelerSlice.actions.setActivePageNo(checkItem.firstPage));
                          }}
                        >
                          <span>{checkItem.name}</span>
                          <div className={s.checks_icon}>{statusToIcon(checkItem.status)}</div>
                        </div>
                        {checkItem.status !== 'failed' && (
                          <button
                            onClick={() => handleManualOverride(checkItem.partId)}
                            className={s.checks_button}
                            data-testid={'check-override'}
                          >
                            <CheckmarkIcon />
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          }
        } else {
          const success = check.status === 'succeeded';
          content = (
            <div key={check.id} className={s.checks_row_group} data-testid={`sidebar-check-${check.id}`}>
              <div key={check.name} className={clsx(s.checks_row_wrapper, { [s.interactive]: !success })}>
                <div
                  className={clsx(s.checks_row, {
                    [s.checks_row__checked]: check.status === 'succeeded',
                  })}
                >
                  <span>{name}</span>
                  <div className={s.checks_icon}>{statusToIcon(check.status)}</div>
                </div>
                {check.id === 'ocrConfidence' && (
                  <button
                    data-testid={'check-override'}
                    onClick={() => handleManualOverride()}
                    className={s.checks_button}
                  >
                    <CheckmarkIcon />
                  </button>
                )}
              </div>
            </div>
          );
        }
        if (description && check.status !== 'succeeded') {
          return (
            <Tooltip
              key={check.id}
              content={
                <div
                  style={{
                    width: '314px',
                    maxWidth: '314px',
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                  }}
                >
                  <p>{description}</p>
                </div>
              }
              alignment={'start'}
              position={'top'}
              lightTheme
            >
              {content}
            </Tooltip>
          );
        }
        return content;
      })}
    </div>
  );
};

export default DocumentLabelerSideChecks;
