import React from 'react';
import { ReactComponent as PlusIcon } from '../../../../shared/assets/svg/plus-icon.svg';
import se from '../../../../shared/styles/component/admin/admin-section.module.scss';
import AdminMultiSelectDialog from '../AdminMultiSelectDialog.tsx';
import AdminSortableList from '../AdminSortableList.tsx';

interface Props {
  hidden?: boolean;
  title?: string;
  description?: string;
  value: any;
  onChange: any;
  options: any[];
  showDialog: any;
}

const FormMultiSelectField: React.FC<Props> = ({
  hidden,
  title,
  description,
  value,
  onChange,
  options,
  showDialog,
}) => {
  if (hidden) return null;
  return (
    <div className={se.item}>
      <div className={se.item_text}>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className={se.item_action}>
        <div className={se.item_fields}>
          {value && options && value?.length !== options?.length && (
            <button
              type="button"
              className={se.item_field}
              onClick={() =>
                showDialog(
                  <AdminMultiSelectDialog
                    title={title}
                    description={description}
                    selectedTypes={value ?? []}
                    handleCheckTypes={(e) => onChange(e)}
                    optionList={options}
                  />
                )
              }
            >
              <PlusIcon className={se.field_add} />
            </button>
          )}
          <AdminSortableList
            detailedItems={options}
            items={value}
            setItems={(e) => onChange(e)}
            handleRemoveType={(e) => {
              const newValue = value.filter((item) => item !== e);
              onChange(newValue);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormMultiSelectField;
