import { cloneDeep, isEqual } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

export type ChangeSaveCallback<T> = (currentState: T) => Promise<void>;

function useChangeTracker<T>(initialState: T, saveCallback: ChangeSaveCallback<T>) {
  const [state, setState] = useState(initialState);
  const [originalState, setOriginalState] = useState(initialState);
  const [hasChanges, setHasChanges] = useState(false);
  const [changedFields, setChangedFields] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<number | null>(null);

  const reset = useCallback(() => {
    setState(originalState);
    setHasChanges(false);
    setChangedFields([]);
  }, [originalState]);

  const handleInput = (value: any, field: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      const fields = field.split('.');
      fields.reduce((acc, cur, i) => (acc[cur] = fields.length - 1 === i ? value : acc[cur] || {}), copy);
      return copy;
    });
  };

  const save = useCallback(
    async (e) => {
      e?.preventDefault();
      if (typeof saveCallback === 'function') {
        try {
          setSaving(true);
          setError(null);
          await saveCallback(state);
          setOriginalState(state);
          setHasChanges(false);
          setChangedFields([]);
        } catch (err: any) {
          if (err.response) {
            setError(err.response.status);
          } else {
            setError(500);
          }
        } finally {
          setSaving(false);
        }
      }
    },
    [saveCallback, state]
  );
  useDeepCompareEffect(() => {
    if (initialState) {
      console.log('SETTING INITIAL STATE', initialState);
      setState(initialState);
      setOriginalState(initialState);
    }
  }, [initialState]);

  useEffect(() => {
    // Create a set containing keys from both initialState and state
    const allKeys = new Set([...Object.keys(initialState), ...Object.keys(state)]);

    const changes = Array.from(allKeys).reduce((acc, key) => {
      if (!isEqual(initialState[key], state[key])) {
        acc.push(key);
      }
      return acc;
    }, []);

    setHasChanges(changes.length > 0);
    setChangedFields(changes);
  }, [initialState, state]);

  return {
    handleInput,
    state,
    setState,
    hasChanges,
    changedFields,
    reset,
    save,
    saving,
    error,
  };
}

export default useChangeTracker;
