import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { sleep } from '../../../shared/helpers/helpers';
import { useModal } from '../../../shared/hooks/useModal.tsx';
import { UrlParams } from '../../../shared/models/generic';
import { getRawPDF } from '../../../shared/store/documentSlice.ts';
import { patchTopology } from '../../../shared/store/inboxSlice.ts';
import { labelerSlice } from '../../../shared/store/labelerSlice';
import { ReactComponent as EditIcon } from '../../../shared/assets/svg/edit-icon.svg';
import { ReactComponent as DownloadIcon } from '../../../shared/assets/svg/download.svg';
import { ReactComponent as DocumentIcon } from '../../../shared/assets/svg/document-icon.svg';
import { useDispatch, useSelector } from '../../../shared/store/store';
import Tooltip from '../../shared/tooltip/Tooltip.tsx';
import DocumentTypeSwitchModal from '../type-selector/DocumentTypeSwitchModal.tsx';
import s from './viewer/labeler-view.module.scss';

interface Props {}

const DocumentLabelerThumbs: React.FC<Props> = () => {
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);
  const pageImagesMap = useSelector((state) => state.document.pageImagesMap);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const documentCounts = useSelector((state) => state.inbox.documentCounts);
  const activePageNo = useSelector((state) => state.labeler.activePageNo);
  const isThumbsVisible = useSelector((state) => state.labeler.isThumbsVisible);
  const copyStructure = useSelector((state) => state.document.copyStructure);
  const { docId, inboxId }: UrlParams = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { showModal, closeModal } = useModal();
  const { t } = useTranslation();
  const historical = location.pathname.includes('historical');

  useEffect(() => {
    if (activeRef.current) {
      const el = activeRef.current as HTMLImageElement;
      el.scrollIntoView({ inline: 'center', behavior: 'smooth', block: 'center' });
    }
  }, [activePageNo]);

  const currentThumbs = useMemo(() => {
    const current = pageImagesMap[docId];
    return current ? Object.values(current) : [];
  }, [docId, pageImagesMap]);

  const [delayedActive, setDelayedActive] = useState(false);

  useAsyncEffect(async () => {
    if (isThumbsVisible) {
      sleep(300).then(() => setDelayedActive(true));
    } else {
      setDelayedActive(false);
    }
  }, [isThumbsVisible]);

  const activeRef = useRef(null);

  const handleChangeDocType = (bundleId: string, docTypeId: string, docSubTypeId?: string) => {
    const clone = cloneDeep(activeDocument);
    const bundleIndex = clone.topology.parts.findIndex((e) => e.id === bundleId);
    if (bundleIndex > -1) {
      const item = cloneDeep(clone.topology.parts.find((e) => e.id === bundleId));
      item.docTypeId = docTypeId;
      item.docSubtypeId = docSubTypeId;
      dispatch(
        patchTopology(docId, inboxId, { doc_type_id: docTypeId, doc_subtype_id: docSubTypeId }, item.id)
      );
    }
    closeModal();
  };

  const handleDownload = async (topologyId: string) => {
    const isMutation = copyStructure.originalDoc.id !== activeDocument.id;
    await dispatch(getRawPDF(activeDocument.id, inboxId, isMutation, topologyId)).then((res) => {
      const a = document.createElement('a');
      const filename = res.headers['content-disposition']?.match(/filename=(.+)/)[1];
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.setAttribute('target', '_blank');
      a.setAttribute('id', 'temp_download_url');
      a.click();
      a.remove();
    });
  };
  const titleFontSize = (title: string) => {
    const textLength = title.length;
    const maxSize = 14;
    const minSize = 12;
    const shrinkStartLength = 12;
    const shrinkEndLength = 25;

    if (textLength <= shrinkStartLength) {
      return maxSize;
    } else if (textLength > shrinkStartLength && textLength <= shrinkEndLength) {
      const sizeDecrementPerChar = (maxSize - minSize) / (shrinkEndLength - shrinkStartLength);
      return maxSize - (textLength - shrinkStartLength) * sizeDecrementPerChar;
    } else {
      return minSize;
    }
  };

  return (
    <div data-tour={'thumbs'} className={clsx(s.page_thumbs)}>
      {activeDocument?.topology?.parts && currentThumbs ? (
        <>
          {activeDocument.topology.parts.map((group) => {
            const filteredDocTypes = documentCounts?.filter(
              (e) => e.topologyType === group.topologyType ?? 'document'
            );

            const active = group.pages.find((p) => p.bundlePageNo === activePageNo);
            const doctype = documentCounts?.find((e) => e.id === group.docTypeId);
            let subType;
            if (doctype && doctype.subTypes && group.docSubtypeId !== 'NOSUBTYPE')
              subType = doctype.subTypes.find((e) => e.id === group.docSubtypeId);
            const docTypeDetails = docTypeSettings.find((dt) => dt.docTypeId === group.docTypeId);
            let isLowConfidence = false;
            let name = group.name;
            if (docTypeDetails && doctype) {
              name = doctype?.name;
              if (subType) name += ` - ${subType.name}`;
              isLowConfidence = group.confidence < docTypeDetails.settings.approvalThreshold;
            }
            return (
              <div className={s.group} key={group.id}>
                <div
                  className={clsx(
                    s.group_header,
                    { [s.visible]: isThumbsVisible },
                    { [s.low_confidence]: isLowConfidence },
                    { [s.failed]: group.docTypeId === '@PB_NOTYPE' },
                    { [s.active]: active && isThumbsVisible && !historical }
                  )}
                >
                  <div className={s.group_wrapper}>
                    <div
                      className={s.name}
                      onClick={() => {
                        dispatch(labelerSlice.actions.setActivePageNo(group.pages[0].bundlePageNo));
                      }}
                    >
                      {!docTypeDetails && (
                        <DocumentIcon style={{ height: 12, marginBottom: 1, marginLeft: -16 }} />
                      )}
                      {name.length > 15 ? (
                        <Tooltip
                          tooltipStyle={{ maxWidth: 180, padding: 10, lineHeight: 1.2 }}
                          position={'top'}
                          content={name}
                        >
                          <span style={{ fontSize: titleFontSize(name) }}>{name}</span>
                        </Tooltip>
                      ) : (
                        <span style={{ fontSize: titleFontSize(name) }}>{name}</span>
                      )}
                    </div>
                    <div className={s.info}>
                      <div className={s.buttons}>
                        {docTypeDetails && (
                          <button
                            onClick={() => {
                              showModal(
                                <DocumentTypeSwitchModal
                                  description={t('document:typeSwitch.bundlePartDescription')}
                                  documentTypes={filteredDocTypes}
                                  suggestions={group.alternativeClassificationResults ?? []}
                                  handleConfirm={(e) =>
                                    handleChangeDocType(group.id, e.docType.id, e.subType?.id)
                                  }
                                  currentDocType={{
                                    docTypeId: group.docTypeId,
                                    subTypeId: group.docSubtypeId,
                                  }}
                                />
                              );
                            }}
                          >
                            <EditIcon />
                          </button>
                        )}

                        <button onClick={() => handleDownload(group.id)}>
                          <DownloadIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={s.group_items}>
                  {group.pages.map((page) => {
                    const img = currentThumbs.find((t) => t.pageNo === page.bundlePageNo);
                    if (!img) return null;
                    return (
                      <img
                        key={img.pageNo + docId}
                        data-hj-suppress
                        ref={activePageNo === page.bundlePageNo ? activeRef : null}
                        onClick={() => {
                          dispatch(labelerSlice.actions.setActiveEntityPair(null));
                          dispatch(labelerSlice.actions.setActivePageNo(page.bundlePageNo));
                        }}
                        className={clsx(
                          { [s.active]: activePageNo === page.bundlePageNo },
                          { [s.visible]: delayedActive }
                        )}
                        src={img.imageString}
                        alt=""
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={s.group_items}>
          {activeDocument?.dimensions.map((_, i) => {
            const img = currentThumbs.find((t) => t.pageNo === i + 1);
            if (!img) return null;
            return (
              <img
                key={img.pageNo + docId}
                data-hj-suppress
                ref={activePageNo === i + 1 ? activeRef : null}
                onClick={() => {
                  dispatch(labelerSlice.actions.setActiveEntityPair(null));
                  dispatch(labelerSlice.actions.setActivePageNo(i + 1));
                }}
                className={clsx({ [s.active]: activePageNo === i + 1 }, { [s.visible]: delayedActive })}
                src={img.imageString}
                alt=""
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DocumentLabelerThumbs;
