import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from '../../../shared/assets/svg/cross-icon.svg';
import { ReactComponent as SearchIcon } from '../../../shared/assets/svg/search-icon.svg';
import { IClientFieldType } from '../../../shared/helpers/converters/fieldtype.ts';
import { extendedSearch, globalFuseOptions } from '../../../shared/helpers/helpers';
import { useModal } from '../../../shared/hooks/useModal';
import s from '../../../shared/styles/component/admin/admin.module.scss';
import Button from '../../shared/button/Button';
import Checkbox from '../../shared/checkbox/Checkbox';
import Modal from '../../shared/modal/Modal';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};
interface Props {
  selectedTypes: string[];
  handleCheckTypes: (updatedList: string[]) => void;
  optionList: any[];
  title?: string;
  description?: string;
}

const AdminMultiSelectDialog: React.FC<Props> = ({
  selectedTypes,
  handleCheckTypes,
  optionList,
  title,
  description,
}) => {
  const { closeDialog } = useModal();
  const { t } = useTranslation();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(selectedTypes);
  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState<IClientFieldType[]>([]);

  const fuse = new Fuse(fuseData, fuseOptions);

  const handleCheck = (option) => {
    if (selectedCheckboxes.includes(option.id)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((e) => e !== option.id));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, option.id]);
    }
  };
  const handleSave = () => {
    handleCheckTypes(selectedCheckboxes);
    closeDialog();
  };
  const isChecked = useCallback(
    (option: IClientFieldType) => {
      if (selectedCheckboxes) {
        const existing = selectedCheckboxes.findIndex((st) => st === option.id);
        if (existing === -1) {
          return false;
        }
      }
      return true;
    },
    [selectedCheckboxes]
  );

  const selectionState = useMemo(() => {
    if (selectedCheckboxes.length === 0) {
      return 'none';
    } else if (searchResults.length === selectedCheckboxes.length) {
      return 'all';
    } else {
      return 'some';
    }
  }, [searchResults, selectedCheckboxes]);

  const handleCheckAll = () => {
    if (selectionState === 'all') {
      setSelectedCheckboxes([]);
    } else if (selectionState === 'some') {
      setSelectedCheckboxes(searchResults.map((o) => o.id));
    } else {
      setSelectedCheckboxes(searchResults.map((o) => o.id));
    }
  };

  const handleSubtypeInput = (value) => {
    if (value === '') {
      setSearchResults(optionList ?? []);
    } else {
      setSearchResults(extendedSearch(value, fuse));
    }
  };

  useEffect(() => {
    if (optionList) {
      setSearchResults(optionList ?? []);
      setFuseData(optionList ?? []);
    }
  }, [optionList]);

  return (
    <Modal isDialog>
      <div className={s.dialog_container}>
        <div className={s.dialog_header}>
          <span className={s.dialog_title}>{title ?? 'Select options'}</span>
          <CrossIcon className={s.dialog_cross} onClick={closeDialog} />
        </div>
        <div className={s.dialog_body}>
          <p className={s.dialog_text}>
            {description ?? 'Select the fields you want to include in this document type.'}
          </p>

          <div className={s.multi_select_wrapper}>
            <div
              data-testid="multi-select-search"
              className={clsx(s.multi_select_search)}
              style={{ marginBottom: 8 }}
            >
              <input
                onChange={(e) => handleSubtypeInput(e.target.value)}
                placeholder={'Filter'}
                className={s.search}
                type="text"
              />
              <SearchIcon className={s.search_icon} />
            </div>
            <div
              data-testid="multi-select-all"
              className={s.multi_select_row}
              style={{ marginBottom: 8 }}
              onClick={handleCheckAll}
            >
              <span>
                {selectionState !== 'all'
                  ? `${t('admin:multiSelect.select')}`
                  : `${t('admin:multiSelect.deselect')}`}
              </span>
              <Checkbox
                onClick={handleCheckAll}
                checked={selectionState === 'all'}
                indeterminate={selectionState === 'some'}
              />
            </div>
            <div
              className={s.multi_select_container}
              style={searchResults.length >= 5 ? { marginRight: -8, paddingRight: 4 } : {}}
            >
              {searchResults.map((option) => {
                return (
                  <div
                    data-testid="multi-select-option"
                    key={option.id}
                    className={s.multi_select_row}
                    onClick={() => handleCheck(option)}
                  >
                    <span>{option.name}</span>
                    <Checkbox onClick={() => handleCheck(option)} checked={isChecked(option)} />
                  </div>
                );
              })}
            </div>
          </div>

          <Button
            data-testid="multi-select-confirm"
            className={s.popup_button}
            onClick={handleSave}
            text={t('admin:multiSelect.confirm')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AdminMultiSelectDialog;
