import { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createEmptyRow, IClientConnector } from '../../../../../shared/helpers/converters/connector.ts';
import { endpointClientToRaw } from '../../../../../shared/helpers/converters/endpoint.ts';
import { IClientWebhook, webhookClientToRaw } from '../../../../../shared/helpers/converters/webhook.ts';
import { errorMap } from '../../../../../shared/helpers/helpers.ts';
import useChangeTracker, { ChangeSaveCallback } from '../../../../../shared/hooks/useChangeTracker.tsx';
import { useModal } from '../../../../../shared/hooks/useModal.tsx';
import {
  clientConnectorsSelector,
  clientWebhookSelector,
  patchEndpoint,
  patchWebhook,
  postEndpoint,
  postWebhook,
} from '../../../../../shared/store/adminSlice.ts';
import { useSelector } from '../../../../../shared/store/store.ts';
import se from '../../../../../shared/styles/component/admin/admin-section.module.scss';
import FormBodyHeader from '../../../components/form/FormBodyHeader.tsx';
import FormInputField from '../../../components/form/FormInputField.tsx';
import FormMultiSelectField from '../../../components/form/FormMultiSelectField.tsx';
import FormRequestField from '../../../components/form/FormRequestField.tsx';
import FormSection from '../../../components/form/FormSection.tsx';
import { DropdownOption } from '../../../../shared/dropdown/StyledSelect.tsx';

const AdminWebhookEdit: React.FC = () => {
  const clientWebhooks = useSelector(clientWebhookSelector);
  const clientConnectors = useSelector(clientConnectorsSelector);
  const inboxes = useSelector((state) => state.admin.inboxes);

  const { webhookId } = useParams();
  const { t } = useTranslation();
  const { showDialog } = useModal();
  const navigate = useNavigate();

  const handleInput = (value: any, field: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy[field] = value;
      return copy;
    });
  };

  const addRow = (type: 'headers' | 'payload' | 'params') => {
    setState((prevState: IClientWebhook) => {
      const copy = cloneDeep(prevState);
      const item = copy.endpoint[type] ?? [];
      copy.endpoint[type] = [...item, createEmptyRow()];
      return copy;
    });
  };

  const deleteRow = (index: number, type: 'headers' | 'payload' | 'params') => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy.endpoint[type][index] = { ...copy.endpoint[type][index], markedForDelete: true };
      return copy;
    });
  };

  const editRow = (
    index: number,
    target: 'key' | 'value' | 'valueType' | 'lock' | 'all',
    value: any,
    type: 'headers' | 'payload' | 'params'
  ) => {
    setState((fs) => {
      const copy = cloneDeep(fs);

      // Simplified the item assignment by utilizing the isAuth flag directly in the item assignment.
      const items = copy.endpoint[type] ?? [];
      const item = items[index];

      // Switch statement used to make the code more readable and easier to manage in the future.
      switch (target) {
        case 'value':
          item.value.content = value;
          break;
        case 'key':
          value = value.replaceAll(' ', '-');
          if (item.error && item.error.key === target && value.length >= item.key.length) {
            return copy;
          }
          item.error = undefined;
          // Nested objects validation only when the type is 'payload'.
          if (type === 'payload' && value.includes('__')) {
            validateNestedObjects(items, value, item);
          }
          item.key = value;
          break;
        case 'valueType':
          item.value.type = value;
          item.tempLocked = value === '@PB_SECRET';
          if (value !== '@PB_SECRET') {
            item.locked = false;
          }
          break;
        case 'all':
          items[index] = value;
          break;
        default:
          item.tempLocked = value;
      }

      return copy;
    });
  };
  // Externalized validation logic to its own function for better readability and maintainability.
  const validateNestedObjects = (items: any[], value: string, item: any) => {
    const activeItems = items.filter((e) => !e.markedForDelete);
    const currentKeyGroups: string[] = value.split(/__/g);
    const sameParentKeyItems = activeItems.filter((e) => e?.key.includes(currentKeyGroups[0]));

    sameParentKeyItems.forEach((sameParentItem) => {
      const parentItemGroups = sameParentItem.key.split(/__/g);
      if (parentItemGroups.length === currentKeyGroups.length - 1) {
        const hasDirectParent = isEqual(
          parentItemGroups,
          currentKeyGroups.slice(0, currentKeyGroups.length - 1)
        );
        if (hasDirectParent) {
          item.error = {
            key: 'key',
            msg: 'Cannot create nested object while parent is already defined',
          };
        }
      }
    });
  };

  const handleSave: ChangeSaveCallback<IClientWebhook> = async () => {
    const rawWebhook = webhookClientToRaw(state);
    const rawEndpoint = endpointClientToRaw(state.endpoint);

    if (webhookId && initialState.endpoint?.id) {
      return patchWebhook(state.id, rawWebhook).then(() => {
        patchEndpoint(state.endpoint.id, rawEndpoint);
        navigate(`/admin/webhooks/${state.id}`);
      });
    } else {
      return postEndpoint(rawEndpoint).then(async (res) => {
        rawWebhook.endpoint_id = res.data.id;
        await postWebhook(rawWebhook).then((res) => {
          navigate(`/admin/webhooks/${res.data.id}`);
        });
      });
    }
  };

  const initialState: IClientWebhook = useMemo(() => {
    if (webhookId !== 'new' && clientWebhooks && clientWebhooks.length > 0) {
      return clientWebhooks.find((e) => e.id === webhookId);
    }

    return {
      id: null,
      name: '',
      active: false,
      events: [],
      inboxes: [],
      actionTypes: [],
      endpoint: {
        id: null,
        name: '',
        type: 'http',
        connector: null,
        useDefaultPayload: true,
        path: '',
        payload: [createEmptyRow()],
        headers: [createEmptyRow()],
        params: [createEmptyRow()],
        method: 'POST',
      },
    } as IClientWebhook;
  }, [webhookId, clientWebhooks]);

  const mappedInboxes = useMemo(() => {
    return inboxes.map((i) => ({ id: i.id, name: i.settings.name }));
  }, [inboxes]);

  const triggerOptions = useMemo(() => {
    return [
      { id: 'document.available', name: 'Document Available' },
      { id: 'document.action', name: 'Document Action' },
    ];
  }, []);

  const triggerActions = useMemo(() => {
    return [
      { id: 'approve', name: 'Approve' },
      { id: 'delete', name: 'Delete' },
      { id: 'bounce', name: 'Bounce' },
    ];
  }, []);

  const connectorOptions = useMemo(() => {
    if (clientConnectors && clientConnectors.length > 0) {
      return clientConnectors
        .filter((ct) => ct?.type === 'http')
        .map((ct) => ({
          value: ct.id,
          label: ct.name,
        })) as DropdownOption[];
    }
    return [];
  }, [clientConnectors]);

  const connectorDefault = useMemo(() => {
    if (connectorOptions && connectorOptions.length > 0) {
      if (initialState?.endpoint?.connector?.id) {
        return connectorOptions.find((e) => e.value === initialState?.endpoint.connector.id);
      }
      return connectorOptions[0];
    } else {
      return null;
    }
  }, [initialState, connectorOptions]);

  const { save, saving, hasChanges, state, setState, error } = useChangeTracker<IClientWebhook>(
    initialState,
    handleSave
  );
  const handleDropdownChange = useCallback(
    (e) => {
      setState((fs) => {
        const changed = cloneDeep(fs);
        if (!fs || !connectorOptions?.length) return fs;
        if (!changed.endpoint) changed.endpoint = { path: '', connector: null, id: null };
        changed.endpoint.connector = clientConnectors.find((ct) => ct.id === e.value) as IClientConnector;
        return changed;
      });
    },
    [setState, connectorOptions?.length, clientConnectors]
  );

  return (
    <form onSubmit={save} className={se.form_body}>
      <FormBodyHeader
        errorMessage={errorMap[error] ?? error}
        hasChanges={hasChanges}
        saving={saving}
        title={state?.name || t('admin:webhooks.add')}
      />
      <div className={se.sections}>
        <FormSection title={t('admin:webhooks.generalInfo')}>
          <FormInputField
            value={state?.active ?? true}
            type={'toggle'}
            label={t('admin:webhooks.enabled')}
            description={t('admin:webhooks.enabledDescription')}
            onChange={() => handleInput(!state?.active, 'active')}
          />
          <FormInputField
            required
            value={state?.name}
            type={'text'}
            label={t('admin:webhooks.name')}
            description={t('admin:webhooks.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            placeholder={'Webhook Name'}
          />
          <FormInputField
            value={webhookId}
            hidden={webhookId === 'new'}
            type={'text'}
            label={t('admin:webhooks.id')}
            description={t('admin:webhooks.idDescription')}
            isPaperboxOnly
            isCopyField
          />
        </FormSection>

        <FormSection title={t('admin:webhooks.connection')}>
          <FormInputField
            hidden={!connectorOptions?.length}
            value={connectorOptions?.find((e) => e.value === state?.endpoint?.connector?.id)}
            label={t('admin:webhooks.connector')}
            description={t('admin:webhooks.connectorDescription')}
            type="dropdown"
            dropdownOptions={connectorOptions}
            defaultDropdownOption={connectorDefault}
            onChange={handleDropdownChange}
          />
          <FormInputField
            value={state?.endpoint?.path}
            type="text"
            label={t('admin:webhooks.url')}
            description={t('admin:webhooks.urlDescription')}
            onChange={(val) =>
              setState((fs) => {
                const copy = cloneDeep(fs);
                if (val[0] !== '/') val = '/' + val;
                copy.endpoint.path = val.replace(' ', '-');
                return copy;
              })
            }
            placeholder={'/path/example'}
          />
        </FormSection>
        <FormSection title={t('admin:webhooks.triggers')}>
          <FormMultiSelectField
            title={t('admin:webhooks.triggerEvent')}
            description={t('admin:webhooks.triggerEventDescription')}
            value={state?.events}
            onChange={(newValue) => setState({ ...state, events: newValue })}
            options={triggerOptions}
            showDialog={showDialog}
          />
          <FormMultiSelectField
            title={t('admin:webhooks.triggerInboxes')}
            description={t('admin:webhooks.triggerInboxesDescription')}
            value={state?.inboxes}
            onChange={(newValue) => setState({ ...state, inboxes: newValue })}
            options={mappedInboxes}
            showDialog={showDialog}
          />
          <FormMultiSelectField
            title={t('admin:webhooks.triggerActionTypes')}
            description={t('admin:webhooks.triggerActionTypesDescription')}
            value={state?.actionTypes}
            onChange={(newValue) => setState({ ...state, actionTypes: newValue })}
            options={triggerActions}
            showDialog={showDialog}
          />
        </FormSection>
        <FormSection title={t('admin:webhooks.dataConfig')}>
          <FormRequestField
            testId={'data-params'}
            editRow={(index, t, v) => editRow(index, t, v, 'params')}
            description={t('admin:webhooks.queryParamsDescription')}
            label={t('admin:webhooks.queryParams')}
            deleteRow={(index) => deleteRow(index, 'params')}
            addRow={() => addRow('params')}
            items={state?.endpoint?.params}
          />

          <FormRequestField
            testId={'data-headers'}
            editRow={(index, t, v) => editRow(index, t, v, 'headers')}
            description={t('admin:webhooks.headersDescription')}
            label={t('admin:webhooks.headers')}
            deleteRow={(index) => deleteRow(index, 'headers')}
            addRow={() => addRow('headers')}
            items={state?.endpoint?.headers}
          />
          <FormInputField
            type={'toggle'}
            value={state?.endpoint?.useDefaultPayload ?? true}
            label={t('admin:webhooks.defaultPayload')}
            description={t('admin:webhooks.defaultPayloadDescription')}
            onChange={() => {
              setState((fs) => {
                const copy = cloneDeep(fs);
                copy.endpoint.useDefaultPayload = !state?.endpoint.useDefaultPayload;
                return copy;
              });
            }}
          />
          <FormRequestField
            testId={'data-payload'}
            hidden={state?.endpoint?.useDefaultPayload}
            editRow={(index, t, v) => editRow(index, t, v, 'payload')}
            description={t('admin:webhooks.customPayloadDescription')}
            label={t('admin:webhooks.customPayload')}
            deleteRow={(index) => deleteRow(index, 'payload')}
            addRow={() => addRow('payload')}
            items={state?.endpoint?.payload}
          />
          {/*<FormInputField*/}
          {/*  hidden={state?.endpoint.useDefaultPayload }*/}
          {/*  value={ true}*/}
          {/*  // value={state?.endpoint.unFlatten ?? true}*/}
          {/*  type={'toggle'}*/}
          {/*  label={t('admin:webhooks.unflatten')}*/}
          {/*  description={t('admin:webhooks.unflattenDescription')}*/}
          {/*  onChange={() => {*/}
          {/*    setState((fs) => {*/}
          {/*      const copy = cloneDeep(fs);*/}
          {/*      copy.endpoint.unFlatten = !state?.endpoint.unFlatten;*/}
          {/*      return copy;*/}
          {/*    });*/}
          {/*  }}*/}
          {/*/>*/}
        </FormSection>
      </div>
    </form>
  );
};

export default AdminWebhookEdit;
