import { IClientEndpoint, IClientEndpointHTTP } from './endpoint.ts';

interface IRawHTTPWebhook {
  id?: string;
  name: string;
  active: boolean;
  events: string[];
  inboxes: string[];
  action_types: string[];
  endpoint_id: string;
}

export type IRawWebhook = IRawHTTPWebhook;

export interface IClientWebhookHTTP {
  id: string;
  name: string;
  active: boolean;
  events: string[];
  inboxes: string[];
  actionTypes: string[];
  endpoint: IClientEndpointHTTP;
}

export type IClientWebhook = IClientWebhookHTTP;

export const webhookRawToClient = (rawWebhook: IRawWebhook, endpoints: IClientEndpoint[]): IClientWebhook => {
  const endpoint = endpoints.find((e) => e.id === rawWebhook.endpoint_id);
  return {
    id: rawWebhook.id,
    name: rawWebhook.name,
    active: rawWebhook.active,
    events: rawWebhook.events,
    inboxes: rawWebhook.inboxes,
    actionTypes: rawWebhook.action_types,
    endpoint: endpoint as IClientEndpointHTTP,
  };
};

export const webhookClientToRaw = (webhook: IClientWebhook): IRawWebhook => {
  return {
    name: webhook.name,
    active: webhook.active,
    events: webhook.events,
    inboxes: webhook.inboxes,
    action_types: webhook.actionTypes,
    endpoint_id: webhook.endpoint.id,
  };
};
